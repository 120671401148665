import { Button, Flex } from "@chakra-ui/react";
import {
  Card,
  HeaderTable,
  Pagination,
  SearchInput,
  TableAdminPartner,
} from "@components";
import { AdminContext } from "context";
import { employerField, EOrder } from "enums";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getEmployerList } from "services";
import { IEmployer, IPagable, ISortTable } from "types";
import { EmployerModal, configColumnsEmployers } from "./components";
import { PAGE_SIZE } from "@constants";

export const EmployerList = () => {
  const navigate = useNavigate();
  const { filterDate, setIsFetching, isFetching } = useContext(AdminContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = useMemo(() => {
    return searchParams.get("page") || "1";
  }, [searchParams]);
  const searchParam = useMemo(() => {
    return searchParams.get("search") || "";
  }, [searchParams]);
  const [employers, setEmployers] = useState<IPagable<IEmployer[]>>();
  const [sortTable, setSortTable] = useState<ISortTable>({
    orderBy: "updatedTime",
    order: EOrder.DESC,
  });
  const [openModalAddEmployer, setOpenModalAddEmployer] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const getEmployers = useCallback(async () => {
    if (isNaN(+pageParam)) return navigate(`/admin/employers`);
    const currentParams = Object.fromEntries(searchParams.entries());
    const res = await getEmployerList(
      filterDate,
      decodeURIComponent(searchParam),
      +pageParam,
      sortTable
    );
    setEmployers(res);
    if (res?.pageCount < +pageParam) {
      setSearchParams({ ...currentParams, page: "1" });
    }
  }, [pageParam, searchParams, filterDate, searchParam, sortTable]);

  useEffect(() => {
    (async () => {
      try {
        if (isNaN(parseInt(pageParam))) return navigate(`/admin/employers`);
        setIsFetching(true);
        const currentParams = Object.fromEntries(searchParams.entries());
        const res = await getEmployerList(
          filterDate,
          decodeURIComponent(searchParam),
          parseInt(pageParam),
          sortTable,
          pageSize
        );
        setEmployers(res);
        if (res?.pageCount < parseInt(pageParam)) {
          setSearchParams({ ...currentParams, page: "1" });
        }
      } finally {
        setIsFetching(false);
      }
    })();
  }, [filterDate, searchParam, pageParam, sortTable, pageSize]);

  const handleSortUsers = (sort: ISortTable) => {
    if (isFetching) return;
    setSortTable(sort);
  };

  return (
    <Card extra="w-full p-4 card-user-list">
      <HeaderTable
        title="Employer List"
        addonAfter={
          <Flex gap={4} wrap="wrap" justifyContent="start">
            <SearchInput />
            <Button onClick={() => setOpenModalAddEmployer(true)}>
              New Employer
            </Button>
          </Flex>
        }
      />
      <div className="flex w-full flex-1 flex-col justify-between gap-4 overflow-y-hidden">
        <TableAdminPartner
          listTable={employers?.result ?? []}
          sortTable={sortTable}
          setSortTable={handleSortUsers}
          columns={configColumnsEmployers()}
          action={null}
          changeStatus={null}
          codeAction={null}
          partnerAction={null}
          employerAction={getEmployers}
          columnsConfig={employerField}
          pageSize={pageSize}
        />
        <Pagination<IEmployer>
          pageUsers={employers}
          pageParam={pageParam}
          setPageSize={setPageSize}
        />
      </div>
      <EmployerModal
        showModal={openModalAddEmployer}
        onDecline={() => setOpenModalAddEmployer(false)}
        data={null}
        action={getEmployers}
      />
    </Card>
  );
};

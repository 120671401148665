import { Skeleton } from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AdminContext } from "context";
import { EOrder, subsField } from "enums";
import { useContext, useEffect, useState } from "react";
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ISubscriptionRes, ITable } from "types";
import { ModalCreate } from "views/subscription/components";
import "../style.css";

const rowClass = "border-b-[1px] border-gray-200 h-[42px] py-2";

const SortingIndicator = (order: EOrder) => {
  return order === "asc" ? <IoIosArrowRoundUp /> : <IoIosArrowRoundDown />;
};

export const TableAdmin = <T,>({
  listTable,
  sortTable,
  setSortTable,
  columns,
  columnsConfig = subsField,
  pageSize,
}: ITable<T | any>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const secondPath = location.pathname.split("/")[2];
  const [searchParams] = useSearchParams();
  const [openModalCreateSub, setOpenModalCreateSub] = useState(false);
  const [fieldsModalSub, setFieldsModalSub] = useState<ISubscriptionRes>();
  const { isFetching } = useContext(AdminContext);

  const table = useReactTable({
    data: listTable,
    columns,
    enableMultiSort: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    table.setPageSize(pageSize);
  }, [pageSize]);

  const handleClickRow = (row: any) => {
    switch (secondPath) {
      case "users":
        return navigate(`${row._id}?${searchParams.toString()}`);
      case "system-users":
        return navigate(`${row._id}?${searchParams.toString()}`);
      case "subscription":
        if (!row._id) {
          setFieldsModalSub(row);
          setOpenModalCreateSub(true);
          return;
        }
        return navigate(`${row.user}?${searchParams.toString()}`);
      default:
        return;
    }
  };

  const handleSortTable = (orderBy: string) => {
    setSortTable({
      orderBy,
      order: sortTable.order === EOrder.ASC ? EOrder.DESC : EOrder.ASC,
    });
  };

  return (
    <div className="container-table mt-4 overflow-x-auto overflow-y-auto xl:overflow-x-hidden">
      <table className="w-full overflow-y-hidden">
        <thead className="sticky top-0 hidden bg-white dark:bg-navy-800 md:table-header-group">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="!border-px !border-gray-400">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  onClick={() => handleSortTable(header.column.columnDef.id)}
                  className={`cursor-pointer border-b-[2px] border-gray-500 px-4 py-3 dark:border-gray-200 ${
                    columnsConfig[header.column.columnDef.id]?.class ||
                    "md:w-[5%]"
                  }`}
                >
                  <div className="flex items-center justify-center gap-1">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.columnDef.id === sortTable?.orderBy
                      ? SortingIndicator(sortTable.order)
                      : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {!isFetching ? (
          <tbody className="mobile-table max-h-full overflow-y-auto">
            {listTable.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="cursor-pointer border-b-[1px] border-gray-200 hover:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-800"
                  onClick={() => handleClickRow(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      data-label={
                        columnsConfig[cell.column.id]?.label || cell.column.id
                      }
                      key={cell.id}
                      className={`flex break-all px-2 text-center md:px-4 ${rowClass} flex items-center justify-between dark:border-gray-700 md:table-cell md:border-b-0`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className={`${rowClass} px-4 text-center`}
                >
                  No data
                </td>
              </tr>
            )}
          </tbody>
        ) : (
          <TableSkeleton col={columns.length} />
        )}
      </table>
      <ModalCreate
        showModal={openModalCreateSub}
        onDecline={() => setOpenModalCreateSub(false)}
        data={fieldsModalSub}
      />
    </div>
  );
};

const TableSkeleton = ({ col }: { col: number }) => {
  return (
    <tbody>
      {Array.from({ length: 10 }).map((_, index) => (
        <tr key={index}>
          <td colSpan={col} className={`px-0 ${rowClass}`}>
            <Skeleton height="20px" />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export * from "./HeaderTable";

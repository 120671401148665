import { Button, Flex } from "@chakra-ui/react";
import { Card, ConfirmDialog, DateRangePicker, HeaderTable, Pagination, SearchInput } from "@components";
import TableAdminPartner from "components/TablePartner";
import { AdminContext } from "context";
import { EActionType, EOrder, EToastStatus, EPartnerActionType, EDateParam } from "enums";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { deletePartner, getPartnerList, updatePartner } from "services";
import { IPagable, IPartnerRes, ISortTable } from "types";
import { AddPartnerModal } from "./components/AddPartnerModal";
import { columnsPartners } from "./components/columns";
import { PAGE_SIZE } from "@constants";

export const PartnerList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page") || "1";
  const searchParam = searchParams.get("search") || "";
  const [partnerId, setPartnerId] = useState("");
  const [openModalAddPartner, setOpenModalAddPartner] = useState(false);
  const {
    setIsFetching,
    isFetching,
    currentTimezone: { name, timezone },
    filterDate,
  } = useContext(AdminContext);
  const [pagePartners, setPagePartners] = useState<IPagable<IPartnerRes[]>>();
  const [sortTable, setSortTable] = useState<ISortTable>({
    orderBy: "name",
    order: EOrder.DESC,
  });
  const { showToast } = useContext(AdminContext);
  const [confirmDialog, setConfirmDialog] = useState({
    show: false,
    title: EActionType.DELETE,
    content: <>Do you wan to {EActionType.DELETE} this partner?</>,
  });
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const handleConfirmAction = () => {
    const actions: { [key: string]: (id: string) => void } = {
      [EActionType.DELETE]: handleDeletePartner,
    };
    const selectedAction = actions[confirmDialog.title];
    if (selectedAction) selectedAction(partnerId);
  };
  const { result: listTable }: { result: IPartnerRes[] } = pagePartners || {
    result: [],
  };

  const handleGetPartnerList = useCallback(async () => {
    try {
      if (isNaN(+pageParam)) return navigate(`/admin/partners`);
      setIsFetching(true);
      const currentParams = Object.fromEntries(searchParams.entries());
      const res = await getPartnerList(
        filterDate,
        decodeURIComponent(searchParam),
        +pageParam,
        sortTable,
        pageSize
      );
      setPagePartners(res);
      res?.pageCount < +pageParam &&
        setSearchParams({ ...currentParams, page: "1" });
    } finally {
      setIsFetching(false);
    }
  }, [searchParams, pageParam, sortTable, filterDate, searchParam, pageSize]);

  useEffect(() => {
    handleGetPartnerList();
  }, [searchParam, pageParam, sortTable, filterDate, pageSize]);

  const handleSortUsers = (sort: ISortTable) => {
    if (!isFetching) {
      setSortTable(sort);
    }
  };

  const handleConvertPartner = async (type: EPartnerActionType, data: any) => {
    if (type === EPartnerActionType.Delete) {
      setPartnerId(data._id);
      setConfirmDialog({
        ...confirmDialog,
        content: <div>Do you want to delete </div>,
        show: true,
      });
    }
    if (type === EPartnerActionType.ChangeStatus) {
      handleChangePartnerStatus(data);
    }
    await handleGetPartnerList();
  };

  const handleChangePartnerStatus = async (data: any) => {
    try {
      await updatePartner(data);
      showToast("Success", "Update Partner success!", EToastStatus.SUCCESS);
      await handleGetPartnerList();
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    }
  };

  const handleDeletePartner = async (id: string) => {
    try {
      await deletePartner(id);
      showToast("Success", "Delete Partner success!", EToastStatus.SUCCESS);
      await handleGetPartnerList();
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setConfirmDialog({ ...confirmDialog, show: false });
    }
  };
  return (
    <Card extra="w-full p-4 card-user-list">
      <HeaderTable
        title="Partner List"
        addonAfter={
          <Flex gap={4} flexWrap="wrap">
            <SearchInput placeholder="Search partners" />
            <DateRangePicker
              startParam="startDate"
              endParam="endDate"
              placeholder="Select created time"
            />
            <Button onClick={() => setOpenModalAddPartner(true)}>
              New Partner
            </Button>
          </Flex>
        }
      />
      <div className="flex w-full flex-1 flex-col justify-between gap-4 overflow-y-hidden">
        <TableAdminPartner
          listTable={listTable}
          sortTable={sortTable}
          setSortTable={handleSortUsers}
          columns={columnsPartners}
          action={handleConvertPartner}
          changeStatus={handleConvertPartner}
          codeAction={null}
          partnerAction={handleGetPartnerList}
          pageSize={pageSize}
        />
        <Pagination<IPartnerRes>
          pageUsers={pagePartners}
          pageParam={pageParam}
          setPageSize={setPageSize}
        />
      </div>
      <ConfirmDialog
        showModal={confirmDialog.show}
        onAccept={handleConfirmAction}
        onDecline={() => setConfirmDialog({ ...confirmDialog, show: false })}
        title={confirmDialog.title}
        content={confirmDialog.content}
      />
      <AddPartnerModal
        showModal={openModalAddPartner}
        onDecline={() => setOpenModalAddPartner(false)}
        data={null}
        action={handleGetPartnerList}
      />
    </Card>
  );
};

import { Button, Flex } from "@chakra-ui/react";
import {
  Card,
  DateRangePicker,
  HeaderTable,
  Pagination,
  SearchInput,
  TableAdmin,
} from "@components";
import { PAGE_SIZE } from "@constants";
import { AdminContext } from "context";
import { EOrder, EToastStatus, redemptionField } from "enums";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getRedemptionListService,
  getUrlExportRedemptionService,
} from "services";
import { IPagable, IRedemptionRes, ISortTable } from "types";
import { columnsRedemption } from "./components";

export const RedemptionList = () => {
  const navigate = useNavigate();
  const {
    filterDate,
    setIsFetching,
    isFetching,
    currentTimezone: { name, timezone },
    showToast,
  } = useContext(AdminContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page") || "1";
  const searchParam = searchParams.get("search") || "";
  const [redemptions, setRedemptions] = useState<IPagable<IRedemptionRes[]>>();
  const [sortTable, setSortTable] = useState<ISortTable>({
    orderBy: "email",
    order: EOrder.DESC,
  });
  const [urlExport, setUrlExport] = useState("");
  const { result: listTable }: { result: IRedemptionRes[] } = redemptions || {
    result: [],
  };
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  useEffect(() => {
    (async () => {
      try {
        const url = await getUrlExportRedemptionService();
        setUrlExport(url);
      } catch {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (isNaN(parseInt(pageParam))) return navigate(`/admin/redemptions`);
        setIsFetching(true);
        const currentParams = Object.fromEntries(searchParams.entries());
        const res = await getRedemptionListService(
          filterDate,
          decodeURIComponent(searchParam),
          parseInt(pageParam),
          sortTable,
          pageSize
        );
        setRedemptions(res);
        if (res?.pageCount < parseInt(pageParam)) {
          setSearchParams({ ...currentParams, page: "1" });
        }
      } finally {
        setIsFetching(false);
      }
    })();
  }, [filterDate, searchParam, pageParam, sortTable, pageSize]);

  const handleSortUsers = (sort: ISortTable) => {
    if (isFetching) return;
    setSortTable(sort);
  };

  const onClickExport = () => {
    if (urlExport) {
      window.open(urlExport, "_blank");
    } else {
      showToast(
        "Error",
        "URL not available, Try reload the website!",
        EToastStatus.ERROR
      );
    }
  };

  return (
    <Card extra="w-full p-4 card-user-list">
      <HeaderTable
        title="Redemptions List"
        addonAfter={
          <Flex gap={4} wrap="wrap" justifyContent="start">
            <SearchInput />
            <DateRangePicker
              startParam="startDate"
              endParam="endDate"
              placeholder="Select Date Redeemed"
            />
            <Button onClick={onClickExport}>Export</Button>
          </Flex>
        }
      />
      <div className="flex w-full flex-1 flex-col justify-between gap-4 overflow-y-hidden">
        <TableAdmin
          listTable={listTable}
          sortTable={sortTable}
          setSortTable={handleSortUsers}
          columns={columnsRedemption}
          columnsConfig={redemptionField}
          pageSize={pageSize}
        />
        <Pagination<IRedemptionRes>
          pageUsers={redemptions}
          pageParam={pageParam}
          setPageSize={setPageSize}
        />
      </div>
    </Card>
  );
};

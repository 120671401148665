import { FC, useContext, useEffect, useMemo, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import Select, { SingleValue } from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import { AiOutlineRedo } from "react-icons/ai";

import { ConfirmDialog } from "@components";
import { IEligibleEmployee, IEligibleEmployeeForm, IEmployer } from "types";
import {
  createEligibleEmployee,
  deleteEligibleEmployee,
  getEligibleEmployee,
  regenerateCodeForEmployee,
  updateEligibleEmployee,
} from "services/eligible-employee";
import { EToastStatus } from "enums";
import { AdminContext } from "context";

interface IProps {
  open: boolean;
  onClose: (isReloadList: boolean) => void;
  id?: string;
  employers: IEmployer[];
}

const validationSchema = yup.object().shape({
  employerId: yup.string().required(),
  employeeInfo: yup
    .string()
    .required(() => `Employee ID is required`)
    .matches(
      /^(?:\d+|[A-Za-z0-9]+|[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})$/,
      {
        message:
          "Employee ID must be a number, alphanumeric string, or a valid email address",
      }
    ),
});

export const ModalEmployee: FC<IProps> = ({ open, onClose, id, employers }) => {
  const { showToast } = useContext(AdminContext);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmRegenerate, setShowConfirmRegenerate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingRegenerate, setIsLoadingRegenerate] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [employee, setEmployee] = useState<IEligibleEmployee>();

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        setIsLoading(true);
        const res = await getEligibleEmployee(id);
        if (!res) return;
        setEmployee(res);
      } catch (error: any) {
        showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  const formik = useFormik<IEligibleEmployeeForm>({
    initialValues: {
      employerId: "",
      employeeInfo: "",
    },
    validationSchema,
    onSubmit: async () => {},
  });

  const { resetForm, values, setFieldValue, isValid, errors, dirty } = formik;

  const handleClose = (isReloadingList = false) => {
    resetForm();
    setEmployee(undefined);
    onClose(isReloadingList || isReload);
  };

  useEffect(() => {
    resetForm({
      values: {
        employerId: employee?.employerId ?? "",
        employeeInfo: employee?.employeeInfo ?? "",
      },
    });
  }, [employee?.employerId, employee?.employeeInfo]);

  const onToggleConfirmModal = () => {
    setShowConfirmDelete((p) => !p);
  };

  const onToggleConfirmRegenerate = () => {
    setShowConfirmRegenerate((p) => !p);
  };

  const handleDelete = async () => {
    if (!id) return;
    try {
      setIsLoadingDelete(true);
      await deleteEligibleEmployee(id);
      showToast("Success", "Delete Code successfully!", EToastStatus.SUCCESS);
      setShowConfirmDelete(false);
      handleClose(true);
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const onSubmitForm = async () => {
    try {
      setIsSubmitting(true);
      if (id) {
        await updateEligibleEmployee(id, values);
        showToast("Success", "Update Employee success!", EToastStatus.SUCCESS);
      } else {
        await createEligibleEmployee(values);
        showToast("Success", "Create Employee success!", EToastStatus.SUCCESS);
      }
      handleClose(true);
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeEmployees = (newValue: SingleValue<IEmployer>) => {
    setFieldValue("employerId", newValue ? newValue._id : "");
  };

  const handleRegenerateCode = async () => {
    try {
      setIsLoadingRegenerate(true);
      const newCode = await regenerateCodeForEmployee(id);
      setEmployee((p) => ({
        ...p,
        code: newCode.name,
        canRegenerateCode: false,
      }));
      showToast("Success", "Regenerate code success!", EToastStatus.SUCCESS);
      setIsReload(true);
      onToggleConfirmRegenerate();
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsLoadingRegenerate(false);
    }
  };

  const employerData = useMemo(
    () => employers.find((i) => i._id === values.employerId),
    [values.employerId]
  );

  return (
    <Modal onClose={handleClose} isOpen={open} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{id ? "Edit Employee" : "Add Employee"} </ModalHeader>
        <ModalCloseButton />
        {isLoading ? (
          <div className="mb-10 flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel display="flex" alignItems="center" gap={2}>
                  Employer<span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  placeholder="Select Employer"
                  value={employers.find((i) => i._id === values.employerId)}
                  isMulti={false}
                  onChange={handleChangeEmployees}
                  options={employers}
                  getOptionLabel={(employer: IEmployer) =>
                    employer.employerName
                  }
                  getOptionValue={(employer: IEmployer) => employer._id}
                />
              </FormControl>

              <SimpleGrid columns={2} spacing={6} marginTop={6}>
                <FormControl>
                  <FormLabel display="flex" alignItems="center" gap={2}>
                    TPA Name
                  </FormLabel>
                  <Input
                    name="TPAName"
                    value={employerData?.TPAName}
                    disabled
                  />
                </FormControl>

                <FormControl>
                  <FormLabel display="flex" alignItems="center" gap={2}>
                    Partner Name
                  </FormLabel>
                  <Input
                    name="partnerName"
                    value={employerData?.partnerName}
                    disabled
                  />
                </FormControl>

                <FormControl>
                  <FormLabel display="flex" alignItems="center" gap={2}>
                    Employee ID<span className="text-red-500">*</span>
                  </FormLabel>
                  <Input
                    value={values.employeeInfo}
                    isInvalid={!!errors.employeeInfo}
                    onChange={(e) => {
                      setFieldValue("employeeInfo", e.target.value);
                    }}
                  />
                  {errors.employeeInfo && (
                    <div className="text-md text-red-500">
                      {errors.employeeInfo}
                    </div>
                  )}
                </FormControl>

                {!!id && (
                  <FormControl>
                    <Flex justifyContent="space-between">
                      <FormLabel display="flex" alignItems="center" gap={2}>
                        Code
                      </FormLabel>
                      <Tooltip label="Regenerate code">
                        <Button
                          colorScheme=""
                          size="xs"
                          padding={1}
                          boxShadow="md"
                          isDisabled={!employee?.canRegenerateCode}
                          isLoading={isLoadingRegenerate}
                          onClick={onToggleConfirmRegenerate}
                        >
                          <AiOutlineRedo
                            size={16}
                            color="black"
                            className="dark:!text-white"
                          />
                        </Button>
                      </Tooltip>
                    </Flex>
                    <Input value={employee?.code} disabled />
                  </FormControl>
                )}
              </SimpleGrid>
            </ModalBody>
            <ModalFooter gap={5}>
              {!!id && (
                <Button
                  isLoading={isLoadingDelete}
                  colorScheme="red"
                  onClick={onToggleConfirmModal}
                >
                  Delete
                </Button>
              )}
              <Button
                isLoading={isSubmitting}
                colorScheme="purple"
                isDisabled={!isValid || !dirty}
                onClick={onSubmitForm}
              >
                {id ? "Save" : "Add"}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
      <ConfirmDialog
        showModal={showConfirmDelete}
        onAccept={handleDelete}
        onDecline={onToggleConfirmModal}
        title="delete"
        content="Do you want to delete this employee?"
      />
      <ConfirmDialog
        showModal={showConfirmRegenerate}
        onAccept={handleRegenerateCode}
        onDecline={onToggleConfirmRegenerate}
        title="Confirm"
        content="Do you want to regenerate code for this employee?"
      />
    </Modal>
  );
};

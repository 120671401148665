import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/modal";
import { Button, Card, CardBody, Spinner } from "@chakra-ui/react";
import { ChangeEvent, FC, useContext, useEffect, useRef, useState } from "react";
import Select, { SingleValue } from "react-select";
import { PiUploadSimple } from "react-icons/pi";
import { IoClose } from "react-icons/io5";

import { customStyles } from "@utils";
import { IEmployer, IReadFileImportEmployee } from "types";
import { downloadEligibleEmployeesTemplate, importEligibleEmployees, readFileImportEligibleEmployees } from "services/eligible-employee";
import { AdminContext } from "context";
import { EToastStatus } from "enums";
import { EmployeeImportSummary } from "./EmployeeImportSummary";

interface IProps {
  open: boolean;
  onClose: (isReload: boolean) => void;
  employers: IEmployer[];
  employerSelectedId: string;
}

const ALLOWED_TYPES = [
  'text/csv',
  // 'application/vnd.ms-excel',
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ImportModal: FC<IProps> = ({ open, onClose, employers, employerSelectedId }) => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const { showToast } = useContext(AdminContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [file, setFile] = useState<File>();
  const [employerId, setEmployerId] = useState('');
  const [dragging, setDragging] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fileImportInfo, setFileImportInfo] = useState<IReadFileImportEmployee>()

  useEffect(() => {
    setEmployerId(employerSelectedId);
  }, [employerSelectedId]);

  const handleImport = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('file', file);
      await importEligibleEmployees(employerId, data);
      const employer = employers.find(i => i._id === employerId);
      showToast(
        "Success",
        `Import eligible employees for ${employer.employerName} success!`,
        EToastStatus.SUCCESS,
      );
      handleClose(true);
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReadFileImport = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('file', file);
      const info = await readFileImportEligibleEmployees(employerId, data);
      setFileImportInfo(info);
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = (isReload = false) => {
    onRemoveFile();
    onClose(isReload);
  };

  const handleChangeEmployees = (newValue: SingleValue<IEmployer>) => {
    setEmployerId(newValue ? newValue?._id : '');
  };

  const handleDownload = async () => {
    try {
      setIsLoadingDownload(true);
      const res = await downloadEligibleEmployeesTemplate();
      const url = window.URL.createObjectURL(new Blob([res], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', "eligible-employees-sample.csv");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const validateAndSetFile = (fileList: FileList) => {
    const files = Array.from(fileList).filter(
      i => ALLOWED_TYPES.includes(i.type)
    );

    if (!files.length) {
      setIsError(true);
      return;
    }

    setIsError(false);
    setFile(files?.[0]);
    setFileImportInfo(null);
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    validateAndSetFile((e.target as HTMLInputElement).files);
  };

  const onRemoveFile = () => {
    setFile(null);
    setFileImportInfo(null);
    fileRef.current.value = '';
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    validateAndSetFile(e.dataTransfer.files);
  };

  const handleClick = () => {
    fileRef.current.click();
  };

  return (
    <Modal
      onClose={handleClose}
      isOpen={open}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Eligible Employees</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <div className="flex gap-4">
            <div className="sm:w-[250px]">
              <Select
                styles={customStyles}
                placeholder="Select Employer"
                value={employers.find(i => i._id === employerId)}
                isMulti={false}
                onChange={handleChangeEmployees}
                options={employers}
                getOptionLabel={(employer: IEmployer) => employer.employerName}
                getOptionValue={(employer: IEmployer) => employer._id}
              />
            </div>
            <Button isLoading={isLoadingDownload} onClick={handleDownload}>
              Download template
            </Button>
          </div>

          <input ref={fileRef} accept=".csv" type="file" hidden onChange={onChangeFile} />
          
          <div className="relative">
            <div
              className="mt-5 w-full h-60 border-2 border-dashed border-gray-400 rounded-2xl cursor-pointer flex items-center justify-center"
              onClick={handleClick}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              style={{
                backgroundColor: (dragging || isLoading) ? '#f0f0f0' : '#ffffff',
              }}
            >
              <div className="flex flex-col items-center justify-center">
                <PiUploadSimple size={40} />
                <p className="mt-5 font-bold">Drag & Drop or Choose file to upload</p>
                <p className="font-bold opacity-75 text-sm">Only support CSV file</p>
              </div>
            </div>
            {isLoading && (
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-[rgba(255,255,255,0.5)] rounded-2xl flex items-center justify-center">
                <Spinner color="#422AFB" size="lg" />
              </div>
            )}
          </div>

          {!!file && (
            <Card className="mt-5">
              <CardBody className="!p-3 flex justify-between items-center">
                <p>{file.name}</p>
                <IoClose
                  size={20}
                  className="cursor-pointer"
                  onClick={onRemoveFile}
                />
              </CardBody>
            </Card>
          )}

          <EmployeeImportSummary data={fileImportInfo} />

          {isError && (
            <p className="text-center text-red-600 mt-4">Only CSV files are allowed.</p>
          )}
        </ModalBody>
        <ModalFooter gap={5}>
          {(file && fileImportInfo) ? (
            <Button
              isLoading={isLoading}
              isDisabled={!file || !employerId}
              colorScheme="purple"
              onClick={handleImport}
            >
              Import
            </Button>
          ) : (
            <Button
              isLoading={isLoading}
              isDisabled={!!fileImportInfo}
              colorScheme="purple"
              onClick={handleReadFileImport}
            >
              Process File
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import { Flex } from "@chakra-ui/react";
import {
  Card,
  DateRangePicker,
  HeaderTable,
  Pagination,
  SearchInput,
  TableAdmin,
} from "@components";
import { AdminContext } from "context";
import { EDateParam, EOrder, SubscriptionTypeLabel, subsField } from "enums";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSubscriptionList } from "services";
import { IPagable, ISortTable, ISubscriptionRes } from "types";
import { columnsSubscription } from "./components";
import { ESubsFields } from "enums";
import "./style.css";
import { PAGE_SIZE } from "@constants";

export const SubscriptionList = () => {
  const navigate = useNavigate();
  const {
    filterDate,
    setIsFetching,
    isFetching,
    currentTimezone: { name, timezone },
  } = useContext(AdminContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page") || "1";
  const searchParam = searchParams.get("search") || "";
  const [pageSubscription, setPageSubscription] =
    useState<IPagable<ISubscriptionRes[]>>();
  const [sortTable, setSortTable] = useState<ISortTable>({
    orderBy: "email",
    order: EOrder.DESC,
  });
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const { result: listTable }: { result: ISubscriptionRes[] } =
    pageSubscription || { result: [] };
  const listTableChangeSort = listTable.map((item) => {
    return {
      ...item,
      [ESubsFields.SubscriptionType]:
        SubscriptionTypeLabel[item.subscriptionType],
    };
  });
  useEffect(() => {
    (async () => {
      try {
        if (isNaN(parseInt(pageParam))) return navigate(`/admin/subscription`);
        setIsFetching(true);
        const currentParams = Object.fromEntries(searchParams.entries());
        const res = await getSubscriptionList(
          filterDate,
          decodeURIComponent(searchParam),
          parseInt(pageParam),
          sortTable,
          pageSize
        );
        setPageSubscription(res);
        res?.pageCount < parseInt(pageParam) &&
          setSearchParams({ ...currentParams, page: "1" });
      } catch (err: any) {
        console.log("log-error --- ", err);
      } finally {
        setIsFetching(false);
      }
    })();
  }, [filterDate, searchParam, pageParam, sortTable, pageSize]);

  const handleSortUsers = (sort: ISortTable) => {
    if (!isFetching) {
      setSortTable(sort);
    }
  };

  return (
    <Card extra="w-full p-4 card-user-list">
      <HeaderTable
        title="Subscription List"
        addonAfter={
          <Flex gap={3} wrap="wrap" justifyContent="start">
            <SearchInput />
            <DateRangePicker
              placeholder="Select start date"
              startParam={EDateParam.TRANSACT_START}
              endParam={EDateParam.TRANSACT_END}
            />
            <DateRangePicker
              placeholder="Select end date"
              startParam={EDateParam.EXPIRE_START}
              endParam={EDateParam.EXPIRE_END}
            />
          </Flex>
        }
      />
      <div className="flex w-full flex-1 flex-col justify-between gap-4 overflow-y-hidden">
        <TableAdmin
          listTable={listTableChangeSort}
          sortTable={sortTable}
          setSortTable={(sort) => handleSortUsers(sort)}
          columns={columnsSubscription}
          columnsConfig={subsField}
          pageSize={pageSize}
        />
        <Pagination<ISubscriptionRes>
          pageUsers={pageSubscription}
          pageParam={pageParam}
          setPageSize={setPageSize}
        />
      </div>
    </Card>
  );
};

enum EEligibleEmployeesColumns {
  Id = "_id",
  EmployeeInfo = "employeeInfo",
  EmployerName = "employerName",
  EmployerNickname = "employerNickname",
  TPAName = "TPAName",
  PartnerName = "partnerName",
  Code = "code",
  CreatedTime = "generatedDate",
}

const eligibleEmployeesColumns: {
  [key: string]: { label: string; class: string };
} = {
  [EEligibleEmployeesColumns.EmployerName]: {
    label: "Employer Name",
    class: "md:w-[20%]",
  },
  [EEligibleEmployeesColumns.EmployerNickname]: {
    label: "Employer Nickname",
    class: "md:w-[15%]",
  },
  [EEligibleEmployeesColumns.TPAName]: {
    label: "TPA Name",
    class: "md:w-[10%]",
  },
  [EEligibleEmployeesColumns.PartnerName]: {
    label: "Partner Name",
    class: "md:w-[15%]",
  },
  [EEligibleEmployeesColumns.EmployeeInfo]: {
    label: "Employee ID",
    class: "md:w-[15%]",
  },
  [EEligibleEmployeesColumns.Code]: {
    label: "Premium Code",
    class: "md:w-[10%]",
  },
  [EEligibleEmployeesColumns.CreatedTime]: {
    label: "Created Time",
    class: "md:w-[10%]",
  },
  [EEligibleEmployeesColumns.Id]: {
    label: "Actions",
    class: "md:w-[5%]",
  },
};

export { EEligibleEmployeesColumns, eligibleEmployeesColumns };
